import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedledemo',
  templateUrl: './schedledemo.component.html',
  styleUrls: ['./schedledemo.component.scss']
})
export class SchedledemoComponent implements OnInit {
  scheduleDemo:string ="assets/Img/scheduleDemo.svg";

  constructor() { }

  ngOnInit(): void {
  }

}
