<div class="container">
    <h1 class="txt1">How We Use Cookies</h1>
    <h4 class="txt2">Our website, like many others, stores and retrieves information on your browser using cookies. 
        This information is used to make the site work as you expect it to.  It is not personally identifiable to you, 
        but it can be used to give you a more personalized web experience.</h4>
        <h4 class="txt2">If you want to learn more about the general uses of cookies, please visit the
            <a href="#" class="txtLInk">CookiePro Knowledgebase.</a>
        </h4>
        <h4 class="txt2">You can opt-out of each cookie category (except strictly necessary cookies) by clicking the Cookie Settings link below. This functionality is provided by the
            <a href="#" class="txtLInk">CookiePro Cookie Consent & Website Scanning Solution.</a>
        </h4>

        <button class="btn">Customize Setting</button>

        <h4 class="txt3">Cookie List</h4>
        <h4 class="txt2">A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies – which are cookies from a domain different than the domain of the website you are visiting – for our advertising and marketing efforts. More specifically, we use cookies and other tracking technologies for the following purposes:</h4>

        <h4 class="txt4">Strictly Necessary CookieS</h4>
        <h4 class="txt2">These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.    You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</h4>

        <!--Strictly Necessary CookieS Table-->
        <div class="tbl">
            <table class="table table-borderless">
                <thead>
                  <tr class="firstRowTable">
                    <th scope="col">Cookie Subgroups</th>
                    <th scope="col">Cookies</th>
                    <th scope="col">Cookies Used</th>
                    <th scope="col" class="rightCol">Lifespan</th>
                  </tr>
                </thead>
                <tbody>
                    <tr class="rowsTable">
                        <th scope="col">cookielaw.org</th>
                        <th scope="col">_dc_gtm_UA-xxxxxxxx</th>
                        <th scope="col">1st Party</th>
                        <th scope="col" class="rightCol">365 Days</th>
                      </tr>
                      <tr class="rowsTable">
                        <th scope="col">cookielaw.org</th>
                        <th scope="col">OptanonAlertBoxClosed</th>
                        <th scope="col">1st Party</th>
                        <th scope="col" class="rightCol">30 Days</th>
                      </tr>
                      <tr class="rowsTable">
                        <th scope="col">cookielaw.org</th>
                        <th scope="col">OptanonContent</th>
                        <th scope="col">1st Party</th>
                        <th scope="col" class="rightCol">30 Days</th>
                      </tr>
                      <tr class="rowsTable">
                        <th scope="col">onetrust.com</th>
                        <th scope="col">_cfduid</th>
                        <th scope="col">3rd Party</th>
                        <th scope="col" class="rightCol">0 Days</th>
                      </tr>
                </tbody>
              </table>
        </div>

        <h4 class="txt4">Analytics Cookies</h4>
        <h4 class="txt2">These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.</h4>

        <!--Analytics Cookies Table-->
        <div class="tbl">
            <table class="table table-borderless">
                <thead>
                  <tr class="firstRowTable">
                    <th scope="col">Cookie Subgroups</th>
                    <th scope="col">Cookies</th>
                    <th scope="col">Cookies Used</th>
                    <th scope="col" class="rightCol">Lifespan</th>
                  </tr>
                </thead>
                <tbody>
                    <tr class="rowsTable">
                        <th scope="col">cookielaw.org</th>
                        <th scope="col">_gclxxxx , _ga , _gid,</th>
                        <th scope="col">1st Party</th>
                        <th scope="col" class="rightCol">90 Days</th>
                      </tr>
                      <tr class="rowsTable">
                        <th scope="col">cookielaw.org</th>
                        <th scope="col">_utmc , _utma , _utmz ,_utmv , _utmb,</th>
                        <th scope="col">1st Party</th>
                        <th scope="col" class="rightCol">720 Days</th>
                      </tr>
                </tbody>
              </table>
        </div>

        <h4 class="txt4">Targeting Cookies</h4>
        <h4 class="txt2">These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.    They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</h4>

        <!--Targeting Cookies Table-->
        <div class="tbl">
            <table class="table table-borderless">
                <thead>
                  <tr class="firstRowTable">
                    <th scope="col">Cookie Subgroups</th>
                    <th scope="col">Cookies</th>
                    <th scope="col">Cookies Used</th>
                    <th scope="col" class="rightCol">Lifespan</th>
                  </tr>
                </thead>
                <tbody>
                    <tr class="rowsTable">
                        <th scope="col">google.com</th>
                        <th scope="col">NID</th>
                        <th scope="col">3rd Party</th>
                        <th scope="col" class="rightCol">183 Days</th>
                      </tr>
                      <tr class="rowsTable">
                        <th scope="col">Eloqua.com</th>
                        <th scope="col">ELQSTATUS, ELOQUA</th>
                        <th scope="col">3rd Party</th>
                        <th scope="col" class="rightCol">397 Days</th>
                      </tr>
                      <tr class="rowsTable">
                        <th scope="col">bing.com</th>
                        <th scope="col">MUID</th>
                        <th scope="col">3rd Party</th>
                        <th scope="col" class="rightCol">390 Days</th>
                      </tr>
                      <tr class="rowsTable">
                        <th scope="col">twitter.com</th>
                        <th scope="col">personalization_id</th>
                        <th scope="col">3rd Party</th>
                        <th scope="col" class="rightCol">737 Days</th>
                      </tr>
                </tbody>
              </table>
        </div>
        





</div>
