import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-startfreetrial',
  templateUrl: './startfreetrial.component.html',
  styleUrls: ['./startfreetrial.component.scss']
})
export class StartfreetrialComponent implements OnInit {
  startFreeTrial:string ="assets/Img/startFreeTrial.svg";
  faPhone:string ="assets/Img/faPhone.svg";

  isFocused: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
