<div class="container-fluid first">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 colPrivacy">
                <a class="txt1" routerLink="/privacypolicy">Privacy Policy</a>
                <a class="txt1" routerLink="/termsandconditions">&nbsp;&nbsp;&nbsp;&nbsp;Terms and Conditions</a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 colFaIcon">
                <img [src] = facebook class="img-fluid">&nbsp;&nbsp;
                <img [src] = instagram class="img-fluid">&nbsp;&nbsp;
                <img [src] = twitter class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid seccond">
    <h5 class="h5">Copyright 2021 TruckAssist Inc. All rights reserved</h5>
</div>

