<div class="container">
    <h1 class="txt1">Terms & Conditions</h1>
    <label class="lbl1">Last updated: March 12, 2021</label>

    <h4 class="txt2">Please read these terms and conditions carefully before using Our Service.</h4>

    <h4 class="txt3">Interpretation and Definitions</h4>

    <h4 class="txt4">Interpretation</h4>
    <h4 class="txt5">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</h4>

    <h4 class="txt4">Definitions</h4>
    <h4 class="txt5">For the purposes of this Privacy Policy:</h4>

    <!--Definitions lista-->
    <ul class="ul">
        <li>
            <span class="liBold">Account </span>
            <span>means a unique account created for You to access our Service or parts of our Service.</span>
        </li>
        <li>
            <span class="liBold">Company </span>
            <span>(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to TruckAssist LLC, 1 Cupertino, IL95014.</span>
        </li>
        <li>
            <span class="liBold">Cookies </span>
            <span>are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</span>
        </li>
        <li>
            <span class="liBold">Country </span>
            <span>refers to: Illinois, United States</span>
        </li>
        <li>
            <span class="liBold">Device </span>
            <span>means any device that can access the Service such as a computer, a cellphone or a digital tablet.</span>
        </li>
        <li>
            <span class="liBold">Personal Data </span>
            <span>is any information that relates to an identified or identifiable individual.</span>
        </li>
        <li>
            <span class="liBold">Service </span>
            <span>refers to the Website.</span>
        </li>
        <li>
            <span class="liBold">Service Provider </span>
            <span>means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.            </span>
        </li>
        <li>
            <span class="liBold">Third-party Social Media Service </span>
            <span>refers to any website or any social network website through which a User can log in or create an account to use the Service.</span>
        </li>
        <li>
            <span class="liBold">Usage Data </span>
            <span>refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</span>
        </li>
        <li>
            <span class="liBold">Website </span>
            <span>refers to Truck Assist, accessible from www.truckassist.com
            </span>
        </li>
        <li>
            <span class="liBold">You </span>
            <span>means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</span>
        </li>
    </ul>

    <h4 class="txt3">Acknowledgment</h4>
    <h4 class="txt5">These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
    </h4>
    <h4 class="txt5">Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
    </h4>
    <h4 class="txt5">By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
    </h4>
    <h4 class="txt5">You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
    </h4>
    <h4 class="txt5">Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
    </h4>

    <h4 class="txt3">Links to Other Websites</h4>
    <h4 class="txt5">Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
    </h4>
    <h4 class="txt5">The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
    </h4>
    <h4 class="txt5">We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
    </h4>

    <h4 class="txt3">Termination</h4>
    <h4 class="txt5">We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
    </h4>
    <h4 class="txt5">Upon termination, Your right to use the Service will cease immediately
    </h4>

    <h4 class="txt3">Limitation of Liability</h4>
    <h4 class="txt5">Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.
    </h4>
    <h4 class="txt5">To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
    </h4>
    <h4 class="txt5">Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.
    </h4>

    <h4 class="txt3">"AS IS" and "AS AVAILABLE" Disclaimer</h4>
    <h4 class="txt5">The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
    </h4>
    <h4 class="txt5">Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
    </h4>
    <h4 class="txt5">Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
    </h4>

    <h4 class="txt3">Disputes Resolution</h4>
    <h4 class="txt5">If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
    </h4>

    <h4 class="txt3">For European Union (EU) Users</h4>
    <h4 class="txt5">If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.
    </h4>

    <h4 class="txt3">United States Legal Compliance</h4>
    <h4 class="txt5">You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
    </h4>

    <h4 class="txt3">Severability and Waiver</h4>
    <h4 class="txt4">Severability</h4>
    <h4 class="txt5">If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
    </h4>
    <h4 class="txt4">Waiver</h4>
    <h4 class="txt5">Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
    </h4>

    <h4 class="txt3">Translation Interpretation</h4>
    <h4 class="txt5">These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.
    </h4>

    <h4 class="txt3">Changes to These Terms and Conditions</h4>
    <h4 class="txt5">We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
    </h4>
    <h4 class="txt5">By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
    </h4>

    <h4 class="txt3">Contact Us</h4>
    <h4 class="txt5">If you have any questions about this Privacy Policy, You can contact us:
    </h4>
    <ul>
        <li><h4 class="txt5">By email: pp@truckassist.com</h4></li>
        <li><h4 class="txt5">By visiting this page on our website: www.truckassist.com/contact/pp</h4></li>
        <li><h4 class="txt5">By phone number: 4089961010</h4></li>
    </ul>

</div>