<div class="container">
    <div class="row">
        <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12 rowColumn">
            <span>
                <span class="txt1">Request a</span>
                <span class="txt2"> Demo </span>
                <span class="txt1">today</span>
                <span class="txt1"><br>or when it suits you<br></span>

            </span>

            <p class="txt3">Smart & simple software. Move to a single solution <br>to manage, streamline, and grow your business.</p>

            <div class="txt7"></div>
                <button type="button" class="btn btnGetStarded" routerLink="/thankyou">Get Started</button>
               
               <div class="txt7"></div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 rowColumn">
            <img [src] = scheduleDemo class="img">
        </div>
    </div>

</div>
