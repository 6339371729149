import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from '../app/shared/header/header.component';
import { FooterComponent } from '../app/shared/footer/footer.component';
import { HomeComponent } from '../app/home/home.component';
import { PricingComponent } from '../app/pricing/pricing.component';
import { StartfreetrialComponent } from '../app/startfreetrial/startfreetrial.component';
import { SchedledemoComponent } from '../app/schedledemo/schedledemo.component';
import { ThankyouComponent } from '../app/thankyou/thankyou.component';
import { CookiesComponent } from '../app/cookies/cookies.component';
import { TermsandconditionsComponent } from '../app/termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from '../app/privacypolicy/privacypolicy.component';


const routes: Routes = [
  {path: 'header', component: HeaderComponent},
  {path: 'footer', component: FooterComponent},
  {path: 'home', component: HomeComponent},
  {path: '', component: HomeComponent},
  {path: 'pricing', component: PricingComponent},
  {path: 'startfreetrial', component: StartfreetrialComponent},
  {path: 'schedledemo', component: SchedledemoComponent},
  {path: 'thankyou', component: ThankyouComponent},
  {path: 'cookies', component: CookiesComponent},
  {path: 'termsandconditions', component: TermsandconditionsComponent},
  {path: 'privacypolicy', component: PrivacypolicyComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
