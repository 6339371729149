<div class="container">
    <div class="row">
        <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12 col-12">
            <h1 class="h1Gray">Sign up for a full-featured</h1>
                <h1 class="h1Blue">free 14-day trial</h1>
                <p class="txt1">We are so grateful for the pleasure of serving you and hope we met your expectations.</p>
                <p class="txt2">Please fill out the form to start a free 14-day trial.</p>


                    
                <button type="button" class="btn btnGetStarded">Get Started</button>

                <div class="txt6">
                    <div class="row txt5">
                        <h1 class="txt3">By continuing, you are agreeing to our &nbsp;</h1>
                        <h1 class="txt4">Terms of Service</h1>
                    </div>
                    <div class="row txt5">
                        <h1 class="txt3">and &nbsp;</h1>
                        <h1 class="txt4">Privacy Policy</h1>
                    </div>
                    <div class="row txt5">
                        <h1 class="txt3">Already have an account? &nbsp;</h1>
                        <h1 class="txt4">Login</h1>
                    </div>
                </div>
                
                <div class="txt7"></div>
        </div>
        <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12'">
            <img [src] = startFreeTrial class="img">
        </div>
    </div>
</div>
