export default class truckPrice{
    trucks: number;
    licPerUnit: number;
    achPay: number;
    fax: number;
    gps: number;
    eld: number;

    constructor(trucks: number,licPerUnit: number,achPay: number,fax: number,gps: number,eld: number){

        this.trucks = trucks;
        this.licPerUnit = licPerUnit;
        this.achPay = achPay;
        this.fax = fax;
        this.gps = gps;
        this.eld = eld;
    }
}