import { Component, OnInit } from '@angular/core';
import truckPrice from 'src/Models/truckPrice'

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  AnnuallyOrMonthly: boolean = true;

  NumberOfTrucks: number = 3;
  PriceOfTrucks: number = 0;

  //tableTruckPricing
  truckPriceAnnually: truckPrice[] = [];
  truckPriceMonthly: truckPrice[] = [];

  scrollButton:string ="assets/Img/scrollButton.svg";
  
  public price = "$1,049.00";
  statusAnnually = 'btn-white'
  statusMonthly = 'btn-Gray'
  
  statusRowCard = 'selectRowCard'
  statusRowCard2 = 'rowCard'

  public SelectedValue = 'Dispatch';
  statusDispatch = 'selectRowCard';
  statusLoadManagement = 'rowCard';
  statusDynamicBoard = 'rowCard';
  statusAccounting = 'rowCard';
  statusLoadBilling = 'rowCard';
  statusLoadSettlements = 'rowCard';
  statusLoadIFTA = 'rowCard';
  statusLoadSafety = 'rowCard';
  statusLoadCustomers = 'rowCard';
  statusLoadReporting = 'rowCard';
  statusLoadRepairManagment = 'rowCard';
  statusLoadFuelManagement = 'rowCard';
  statusLoadChat = 'rowCard';
  statusLoadDivisionCompany = 'rowCard';
  statusLoadToDoList = 'rowCard';
  statusLoadScheduler = 'rowCard';
  statusLoadContacts = 'rowCard';
  statusLoadAccounts = 'rowCard';
  statusLoadFileManagment = 'rowCard';
  statusLoadFax = 'rowCard';
  statusLoadMobileApp = 'rowCard';

  constructor() {
    this.truckPriceMonthly.push(new truckPrice(50,10,1,10,25,35));
    this.truckPriceMonthly.push(new truckPrice(100,9,0.7,15,22,30));
    this.truckPriceMonthly.push(new truckPrice(200,8,0.5,20,20,25));
    this.truckPriceMonthly.push(new truckPrice(500,7,0.4,25,17,22));
    this.truckPriceMonthly.push(new truckPrice(1000,6,0.3,30,15,20));

    this.truckPriceAnnually.push(new truckPrice(50,9,1,10,25,35));
    this.truckPriceAnnually.push(new truckPrice(100,8,0.7,15,22,30));
    this.truckPriceAnnually.push(new truckPrice(200,7,0.5,20,20,25));
    this.truckPriceAnnually.push(new truckPrice(500,6,0.4,25,17,22));
    this.truckPriceAnnually.push(new truckPrice(1000,5,0.3,30,15,20));
   }

  ngOnInit(): void {
  }

  ClickAnnually() {
    this.AnnuallyOrMonthly = true;
    this.price = "$1,049.00";
    this.statusAnnually = 'btn-white'
    this.statusMonthly = 'btn-Gray'
    this.ChangeNumberOfTrucks();
  }

  ClickMonthly() {
    this.AnnuallyOrMonthly = false;
    this.price = "$99.00";
    this.statusAnnually = 'btn-Gray'
    this.statusMonthly = 'btn-white'
    this.ChangeNumberOfTrucks();
  }

  ChangeNumberOfTrucks() {
    var NumberOfTrucks = this.NumberOfTrucks - 3;
    var PricePerUnit = 0;


    if (NumberOfTrucks <= 50) {
      if (this.AnnuallyOrMonthly) {
        PricePerUnit = this.truckPriceAnnually[0].licPerUnit;
      }
      else {
        PricePerUnit = this.truckPriceMonthly[0].licPerUnit;
      }
    }
    if (NumberOfTrucks > 50 && NumberOfTrucks <100) {
      if (this.AnnuallyOrMonthly) {
        PricePerUnit = this.truckPriceAnnually[1].licPerUnit;
      }
      else {
        PricePerUnit = this.truckPriceMonthly[1].licPerUnit;
      }
    }
    if (NumberOfTrucks >= 100 && NumberOfTrucks <200) {
      if (this.AnnuallyOrMonthly) {
        PricePerUnit = this.truckPriceAnnually[2].licPerUnit;
      }
      else {
        PricePerUnit = this.truckPriceMonthly[2].licPerUnit;
      }
    }
    if (NumberOfTrucks >= 200 && NumberOfTrucks <500) {
      if (this.AnnuallyOrMonthly) {
        PricePerUnit = this.truckPriceAnnually[3].licPerUnit;
      }
      else {
        PricePerUnit = this.truckPriceMonthly[3].licPerUnit;
      }
    }
    if (NumberOfTrucks >= 500) {
      if (this.AnnuallyOrMonthly) {
        PricePerUnit = this.truckPriceAnnually[4].licPerUnit;
      }
      else {
        PricePerUnit = this.truckPriceMonthly[4].licPerUnit;
      }
    }
    this.PriceOfTrucks = NumberOfTrucks * PricePerUnit;
  }

  selectItem(Item: string){
    switch (Item) {
      case 'Dispatch':
        this.SelectedValue = 'Dispatch:'; 
        this.statusDispatch = 'selectRowCard';
        this.statusLoadManagement = 'rowCard';
        this.statusDynamicBoard = 'rowCard';
        this.statusAccounting = 'rowCard';
        this.statusLoadBilling = 'rowCard';
        this.statusLoadSettlements = 'rowCard';
        this.statusLoadIFTA = 'rowCard';

        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';

        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Load Management': 
      this.SelectedValue = 'Load Management:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'selectRowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Dynamic Board': 
      this.SelectedValue = 'Dynamic Board:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'selectRowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Accounting': 
      this.SelectedValue = 'Accounting:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'selectRowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Billing': 
      this.SelectedValue = 'Billing:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'selectRowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Settlements': 
      this.SelectedValue = 'Settlements:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'selectRowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'IFTA': 
      this.SelectedValue = 'IFTA:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'selectRowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Safety': 
      this.SelectedValue = 'Safety:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'selectRowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Customers': 
      this.SelectedValue = 'Customers:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'selectRowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Reporting': 
      this.SelectedValue = 'Reporting:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'selectRowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'RepairManagment': 
      this.SelectedValue = 'Repair Managment:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'selectRowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'FuelManagement': 
      this.SelectedValue = 'Fuel Management:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'selectRowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Chat': 
      this.SelectedValue = 'Chat:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'selectRowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'DivisionCompany': 
      this.SelectedValue = 'Division Company:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'selectRowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'To-doList': 
      this.SelectedValue = 'To-do List:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'selectRowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Scheduler': 
      this.SelectedValue = 'Scheduler:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'selectRowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Contacts': 
      this.SelectedValue = 'Contacts:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'selectRowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Accounts': 
      this.SelectedValue = 'Accounts:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'selectRowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'FileManagment': 
      this.SelectedValue = 'File Managment:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'selectRowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'Fax': 
      this.SelectedValue = 'Fax:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'selectRowCard';
        this.statusLoadMobileApp = 'rowCard';
      break;
      case 'MobileApp': 
      this.SelectedValue = 'Mobile App:'; 
      this.statusDispatch = 'rowCard';
      this.statusLoadManagement = 'rowCard';
      this.statusDynamicBoard = 'rowCard';
      this.statusAccounting = 'rowCard';
      this.statusLoadBilling = 'rowCard';
      this.statusLoadSettlements = 'rowCard';
      this.statusLoadIFTA = 'rowCard';
        this.statusLoadSafety = 'rowCard';
        this.statusLoadCustomers = 'rowCard';
        this.statusLoadReporting = 'rowCard';
        this.statusLoadRepairManagment = 'rowCard';
        this.statusLoadFuelManagement = 'rowCard';
        this.statusLoadChat = 'rowCard';
        this.statusLoadDivisionCompany = 'rowCard';
        this.statusLoadToDoList = 'rowCard';
        this.statusLoadScheduler = 'rowCard';
        this.statusLoadContacts = 'rowCard';
        this.statusLoadAccounts = 'rowCard';
        this.statusLoadFileManagment = 'rowCard';
        this.statusLoadFax = 'rowCard';
        this.statusLoadMobileApp = 'selectRowCard';
      break;
      
      default:
        break;
    }
  }
}
