<div class="container fontFalmly">
<!--Section one-->
    <h1 class="h1">
        <span class="h1-Gray"> Simple & Transparent </span> <span class="h1-Blue">Pricing</span>
    </h1>
    <h4 class="h4">All features unlocked!</h4>
    <h5 class="h5">Handle your business easier, with a unlimited access to all features and tools.</h5>

    <div class="dgrid gap-2 d-md-block">
        <button type="button" class="btn" (click)="ClickAnnually()" [ngClass]="statusAnnually">Annually</button>
        <button type="button" class="btn" (click)="ClickMonthly()"  [ngClass]="statusMonthly">Monthly</button>
    </div>

        <h1 class="txt1">Base Anuall Plan</h1>
        <h4 class="txt2">3 vehicle licenses included</h4>

        <div class="row1">
            <label class="txt3">{{price}}</label>
        </div>


 <!--Card pricing-->
        <div class="container">
            <div class="row">
        <!--colum Card-->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 columCard">
                <div class="row rowCard" (mouseover)="selectItem('Dispatch')" [ngClass]="statusDispatch">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Dispatch</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Load Management')" [ngClass]="statusLoadManagement">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Load Management</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Dynamic Board')" [ngClass]="statusDynamicBoard">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Dynamic Board</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Accounting')" [ngClass]="statusAccounting"> 
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Accounting</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Billing')" [ngClass]="statusLoadBilling">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Billing</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Settlements')" [ngClass]="statusLoadSettlements">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Settlements</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('IFTA')" [ngClass]="statusLoadIFTA">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">IFTA</h5>
                    </div>
                </div>
            </div>

 <!--colum Card-->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 columCard">
                <div class="row rowCard" (mouseover)="selectItem('Safety')" [ngClass]="statusLoadSafety">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Safety</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Customers')" [ngClass]="statusLoadCustomers">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Customers</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Reporting')" [ngClass]="statusLoadReporting">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Reporting</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('RepairManagment')" [ngClass]="statusLoadRepairManagment">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Repair Managment</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('FuelManagement')" [ngClass]="statusLoadFuelManagement">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Fuel Management</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Chat')" [ngClass]="statusLoadChat">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Chat</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('DivisionCompany')" [ngClass]="statusLoadDivisionCompany">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Division Company</h5>
                    </div>
                </div>
            </div>

 <!--colum Card-->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 columCard">
                <div class="row rowCard" (mouseover)="selectItem('To-doList')" [ngClass]="statusLoadToDoList">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">To-do List</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Scheduler')" [ngClass]="statusLoadScheduler">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Scheduler</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Contacts')" [ngClass]="statusLoadContacts">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Contacts</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Accounts')" [ngClass]="statusLoadAccounts">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Accounts</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('FileManagment')" [ngClass]="statusLoadFileManagment">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">File Managment</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('Fax')" [ngClass]="statusLoadFax">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Fax</h5>
                    </div>
                </div>
                <div class="row rowCard" (mouseover)="selectItem('MobileApp')" [ngClass]="statusLoadMobileApp">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5 class="item">Mobile app</h5>
                    </div>
                </div>
            </div>

  <!--colum Card-->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 columCard">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 rowCardDispatch">
                        <h5 class="itemsText"> {{SelectedValue}} </h5>
                        <h5 class="txtTable4">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                        </h5>
                    </div>
                </div>
                <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 rowCardLink">
                    <a href="#StartFreeTrail" class="rowCardLinkTxt">Start free trail</a>
                </div>
            </div>
            </div>
        </div>
    </div>

     <!--Scroll down-->
     <div id="scrollBtn">    
        <a href="pricing#down"><img [src] = scrollButton class="img2"></a>
        <br><span class="scrollText" id="fontFalmly">Scroll down</span>
    </div>

<!--Section two-->
    <h1 class="h1"  id="down">
        <span class="text4">Don't wander too much</span>
        <h5 class="h5 text5">We have adapted to everyone's needs and requirements. Depending on your needs, we have prepared packages that are flexible and adapt to your requirements. So you can check the additional charges below  that we made just to adapt to your needs If something is bothering you or something is unclear to you, we can schedule a free conversation and meet you to clarify any ambiguities.</h5>
       
        <button type="button" class="btn txt6">Schedule Demo</button>
        <h3 class="txt7">Additional Charges estimate</h3>
    </h1>
       
    <!--Table Additional Charges estimate-->
    <div class="row">
        <table class="table table-borderless">
            <thead>
              <tr class="tRowColor">
                <th scope="col">Trucks</th>
                <th scope="col">Lic. Per Unit</th>
                <th scope="col">ACH Pay</th>
                <th scope="col">FAX</th>
                <th scope="col">GPS</th>
                <th scope="col">ELD</th>
              </tr>
            </thead>
            <tbody *ngIf ="AnnuallyOrMonthly == true">
              <tr *ngFor = "let x of truckPriceAnnually">
                <th scope="col">{{x.trucks}}</th>
                <th scope="col">${{x.licPerUnit}}</th>
                <th scope="col">${{x.achPay}}</th>
                <th scope="col">${{x.fax}}</th>
                <th scope="col">${{x.gps}}</th>
                <th scope="col">${{x.eld}}</th>
              </tr>
            </tbody>

            <tbody *ngIf ="AnnuallyOrMonthly == false">
                <tr *ngFor = "let x of truckPriceMonthly">
                  <th scope="col">{{x.trucks}}</th>
                  <th scope="col">${{x.licPerUnit}}</th>
                  <th scope="col">${{x.achPay}}</th>
                  <th scope="col">${{x.fax}}</th>
                  <th scope="col">${{x.gps}}</th>
                  <th scope="col">${{x.eld}}</th>
                </tr>
              </tbody>
          </table>
    </div>
    
    <div class="txt8">
        <H4 class="txt9">Additional charges are based on average number of trucks in a 30 day period.<br><br>To estimate your monthly charges tell us how many trucks you have.</H4>
        <h4 class="txt10"># of trucks</h4>

        <input (change)="ChangeNumberOfTrucks()" [(ngModel)]='NumberOfTrucks' type="number" min="3" max="1000" class="form-control" class="btn btn-txt10" placeholder="3"  onKeyUp="if(this.value>1000){this.value='1000';}else if(this.value<1){this.value='';}">
    </div>

    <div class="row1">
        <h3 class="txt11">${{PriceOfTrucks}}</h3>
    </div>

    <div class="txt8">
        <button type="button" class="btn btn-txt12" routerLink="/startfreetrial">Start free 14-day Trail</button>
    </div>

</div>