import { Component, OnInit } from '@angular/core';
import {trigger,state,style,animate, transition,keyframes} from '@angular/animations';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  scrollButton:string ="assets/Img/scrollButton.svg";


  constructor() { }

  ngOnInit(): void {
  }

}
