<div class="container"> 
  <nav class="navbar navbar-expand-lg navbar-light">
    <img [src] = Logo class="img" routerLink=''>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
  
    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="pricing">Pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Support</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Log In</a>
        </li>
        <li class="nav-item">
          <button class="btn" routerLink="/schedledemo">Schedule Demo</button>
        </li>
      </ul>
  
    </div>
  
  </nav>
</div>
