import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  facebook:string ="assets/Img/facebook.svg";
  instagram:string ="assets/Img/instagram.svg";
  twitter:string ="assets/Img/twitter.svg";

  constructor() { }

  ngOnInit(): void {
  }

}
